// *{
//   font-family: "Nunito", sans-serif;
//   font-optical-sizing: auto;
//   // font-weight: <weight>;
//   font-style: normal;
// }
.html-element.pp-timeline-card-content{
  line-height: 1.7em;
}

/* CSS HEX */
body{
//   --davys-gray: #4D4846ff;
// --jet: #302B29ff;
// --eerie-black: #1E1D1Cff;
// --night: #121212ff;
// --onyx: #3C3F46ff;

// /* CSS HSL */
// --davys-gray: hsla(17, 5%, 29%, 1);
// --jet: hsla(17, 8%, 17%, 1);
// --eerie-black: hsla(30, 3%, 11%, 1);
// --night: hsla(0, 0%, 7%, 1);
// --onyx: hsla(222, 8%, 25%, 1);

// /* SCSS HEX */
// --davys-gray: #4D4846ff;
// --jet: #302B29ff;
// --eerie-black: #1E1D1Cff;
// --night: #121212ff;
// --onyx: rgb(110 118 137);
// --onyx-dark:rgb(61 64 73);

// /* SCSS HSL */
// --davys-gray: hsla(17, 5%, 29%, 1);
// --jet: hsla(17, 8%, 17%, 1);
// --eerie-black: hsla(30, 3%, 11%, 1);
// --night: hsla(0, 0%, 7%, 1);
// --onyx: hsla(222, 8%, 25%, 1);
// --bg-nav-bar:rgb(61 64 73);

// /* SCSS RGB */
// --davys-gray: rgba(77, 72, 70, 1);
// --jet: rgba(48, 43, 41, 1);
// --eerie-black: rgba(30, 29, 28, 1);
// --night: rgba(18, 18, 18, 1);
// --onyx: rgba(60, 63, 70, 1);

// /* SCSS Gradient */
// --gradient-top: linear-gradient(0deg, #4D4846ff, #302B29ff, #1E1D1Cff, #121212ff, #3C3F46ff);
// --gradient-right: linear-gradient(90deg, #4D4846ff, #302B29ff, #1E1D1Cff, #121212ff, #3C3F46ff);
// --gradient-bottom: linear-gradient(180deg, #4D4846ff, #302B29ff, #1E1D1Cff, #121212ff, #3C3F46ff);
// --gradient-left: linear-gradient(270deg, #4D4846ff, #302B29ff, #1E1D1Cff, #121212ff, #3C3F46ff);
// --gradient-top-right: linear-gradient(45deg, #4D4846ff, #302B29ff, #1E1D1Cff, #121212ff, #3C3F46ff);
// --gradient-bottom-right: linear-gradient(135deg, #4D4846ff, #302B29ff, #1E1D1Cff, #121212ff, #3C3F46ff);
// --gradient-top-left: linear-gradient(225deg, #4D4846ff, #302B29ff, #1E1D1Cff, #121212ff, #3C3F46ff);
// --gradient-bottom-left: linear-gradient(315deg, #4D4846ff, #302B29ff, #1E1D1Cff, #121212ff, #3C3F46ff);
// --gradient-radial: radial-gradient(#4D4846ff, #302B29ff, #1E1D1Cff, #121212ff, #3C3F46ff);
}

.card {
  p,span,b{
      margin-right: 5px;
      display: inline-block !important;
      // span, b{
      //   margin-left: 5px;
      // }
      // margin-left: 5px;
      // &:first{
      //   margin-left: 0px;
      // }
      // white-space: ;
      word-break: break-word;
      // width: 100%;
  }
  
}
:root {
  /* --vs-colors--lightest: rgba(60,60,60,0.26); */
  --greenBg: rgb(53, 143, 171);
  --orangeBg: rgb(53, 143, 171);
  --greenBgLight: rgb(67, 165, 195);
}

.navbar .menu-r .link-3[data-v-1e50989a]{

  border: 2px solid var(--greenBg);
}

.pricing .card{
  display: flex;
  flex-direction: column;
  flex: 1;
  .wrap{
    display: flex;
    flex-direction: column;
    flex: 1;
    .flex.flex-col.items-center.my-5{
      flex: 1;
    }
  }
}

.navbar .menu-r .link-3:hover, .btn.primary:hover, .pricing .card .wrap .btn-call:hover{
  background-color: var(--greenBg) !important;
  opacity: .8;
}

header .main .container .left .cta .btn-2:hover{
 background-color: rgb(225 225 225) !important;
}

.navbar nav a:hover{
  color: var(--greenBg);
}

a{
  cursor: pointer !important;
}

.feature .container .wrap{
  @media(max-width: 460px){
    grid-template-columns: repeat(1, minmax(0px, 1fr)) !important;
  }
}

.navbar{
  box-shadow: 1px 0px 11px rgba(0,0,0,.2);
  padding: 0 1.5rem !important;
}

.cta{
  position: relative;
}

main{
  padding-top: 70px;
}

header .main{
  padding-top: 6rem !important;
}

h2{
  font-size: 2em;
  margin-bottom: 10px;
}

footer .container .copyright{
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}


.theme .container .wrap .card{
  &:hover{
    box-shadow: 0 0 15px rgba(0,0,0,.4);
  }
  box-shadow: 0 0 10px rgba(0,0,0,.2);
  border-radius: 10px ;
  overflow: hidden;
  div.card, a{
    padding-top: 70%;
    height: 0;
    position: relative;
    box-shadow: none !important;
    border-radius: 0 !important;
    :hover{
      box-shadow: none !important;
    }
    
    img{
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 0 !important;
    }
  }
  div{
    // position: absolute;
    // z-index: 2;
    // bottom: 0;
    // left: 0;
    // background-color: #fff;
  }
}


.details_wapper_product a.custom_text_see{
  height: auto !important;
  display: inline-block !important;
  margin-right: 5px !important;
  word-break: break-word !important;
  border-radius: 0.375rem !important;
}
.details_wapper_product{
  @media(max-width: 680px){
    flex-direction: column !important;
    .text-lg {
      width: 100%;
      margin-top: 10px !important;
    }
  }
}

.custom_action_wapper_product{
  @media(max-width: 680px){
    justify-content: space-between;
    width: 100%;
  }
}


.theme .container .wrap .card a.custom_text_see{
  border-radius: 0.375rem !important;
  background-color: rgb(255 154 149/1) !important;
}

.title_of_product{
  padding: 10px 0;
  flex: 1;
  text-align: left;
  font-size: 14px;
  line-height: 1.2rem;
}

@media (max-width: 680px){
  .title_of_product{
    padding: 0;
  }
  header[data-v-396b2ede]{
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .theme[data-v-2139017b]{
    padding-bottom: 0rem !important;
    padding-top: 2rem !important;
  }
  .pricing[data-v-1f7b5efa]{
    padding-top: 2rem !important;
  }
}


.diskon_info{
  .diskon_price{
    font-weight: 600;
    color: #f28e8e;
    font-size: 1.2em;
    position: relative;
    &::after{
      position: absolute;
      content: "";
      width: 106%;
      left: -2px;
      background-color: #f28e8e;
      height: 2px;
      top: 13px;
    }
  }
  .diskon_text{
    font-weight: 500;
  }
  .diskon_value{
    font-weight: 600;
    color: #f28e8e;
  }
}